body {
  background-color: #1d223e;
  color: #ffffff;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.banner {
  width: 100%;
  /* margin-top: 150px; */
}
.custom__container {
  width: calc(100% - 200px);
  margin: 0 auto;
}

.main--container {
  margin-top: 70px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  /* width: 100vw; */
  /* height: 100vh; */
  /* background-color: #000; */
  opacity: 0.5;
}

.modal-wrapper {
  position: absolute;
  top: 100px;
  left: 450px;
  z-index: 1050;
  width: 500px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #fff;
  margin: 0 auto;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}
.nav {
  /* min-height: 170px;
  width: 100%;
  padding: 0; */
  background-color: #13172a;
  box-shadow: 0px 6px 6px #00000029;
  transition: all 0.5s ease-in;
  padding-bottom:30px;
}
.ReactModal__Content {
  overflow: hidden;
}
input {
  background: transparent;
  color: #fff;
}
.banneri {
  margin-bottom: 30px;
  width: 100%;
}
.banneri img {
  width: 100%;
  max-width: 100%;
  /* // height: 300px; */
  object-fit: cover;
}
.advertising img {
  height: 1330px;

  object-fit: contain;

  margin-left: 50px;
}
/* Gradient Scrollbar */

/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(0deg, #414090 0%, #ae105e 100%);
}

::-webkit-scrollbar-thumb {
  background: transparent; 
  box-shadow: 0px 0px 0px 100000vh #414090;
  scrollbar-width: thin;
} */


input,
textarea,
select {
  outline: none;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  /* border: 1px solid green; */
  -webkit-text-fill-color: #fff;
  color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
  outline: none;
  padding: 0;
}

input:focus {
  box-shadow: none !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: auto;
  border-bottom: none !important;
}
.carousel {
  width: 100%;
}
.react-multi-carousel-list {
  /* display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center; */
  overflow: hidden;
  position: relative;
  padding: 30px 0;
}
.form-control {
  background-color: transparent;
  border: none;
}
.form-control:focus {
  background-color: transparent;
  outline: 0;
}
a:hover {
  text-decoration: none;
}
.rc-virtual-list-holder {
  background-color: transparent !important;
}

.rc-virtual-list-holder-inner {
  background: #13172a;
}
.ant-select {
  background: #1d223e !important;
  color: #fff;
}
.ant-select-selector {
  background: #1d223e !important;
  border: none !important;
  border-radius: 8px;
}
.ant-select-item-option-content {
  color: #fff;
}
.ant-select-item-option-content:hover {
  color: #cb2a6f;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}
.ant-select-dropdown {
  background: transparent !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(203, 42, 111 / 20%);
  box-shadow: 0 0 0 2px rgb(203, 42, 1115 / 20%);
  color: #fff;
}

.underline-gradient {
  display: block;
  border-image-source: linear-gradient(90deg, #414090 0%, #ae105e 100%);
  border-image-slice: 1;
  border-bottom: 3px solid;
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;
}

.use-gradient {
  display: block;
  border-image-source: linear-gradient(90deg, #414090 0%, #ae105e 100%);
  border-image-slice: 1;
  border-bottom: 3px solid;
}
.use-gradient p {
  margin: 0;
}

#scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #171e3d;
  border: none;
}

#scroll::-webkit-scrollbar {
  width: 10px;
  background-color: #171e3d;
}

#scroll::-webkit-scrollbar-thumb {
  background-color: #ae105e;
  border: 2px solid #ae105e;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #171e3d;
  /* border-radius: 4px; */
  margin-right: -50px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #171e3d;
}
::-webkit-scrollbar-thumb {
  background-color: #414090;
  border: 2px solid #414090;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.custom--select {
  width: 100%;
  background: none;
  border: none;
  font-weight: bold;
}

.custom--select option {
  background-color: #171e3d;
  color: #fff;
}
@media only screen and (max-width: 1500px) {
  .custom__container {
    width: calc(100% - 100px);
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .custom__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /* overflow:hidden; */
  }
  .react-multi-carousel-list {
    padding: 0 !important;
  }
  .nav {
    height: 100%;
    min-height: 90px;
    padding-bottom:0;
  }
  .single--image img {
    height: 100px;
    object-fit: cover;
    padding-left: 15px;
  }
  .two--ads {
    display: none;
  }
}

.error--msg {
  background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #13131d;
  text-align: center;
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-top: 30px;
  padding: 10px 0;
  display: block !important;
}
.custom--theme{
  background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%) 0%
  0% no-repeat padding-box !important;
  background-color: #3f5cc8 !important;
}


