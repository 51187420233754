.profile--wrapper {
  padding: 50px 0;
  width: 100%;
  background: #1d223e;
  .profile--image--text {
    text-align: center;
    margin-top: 25px;
    p {
      margin-top: 30px;
      text-align: center;
      font: normal normal bold 16px/0px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    span {
      text-align: left;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 0.7;
      vertical-align: text-bottom;
    }
  }
  .profile--image--container {
    // padding: 10px;
    width: 320px;
    height: 320px;
    border: double 4px transparent;
    border-radius: 70%;
    background-image: linear-gradient(#1d223e, #1d223e),
      radial-gradient(circle at bottom left, #3f5cc8, #e12160);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin: 0 auto;
    text-align: center;
    cursor:pointer;
    position:relative;

    .rh5v-Overlay_inner{
      display:none;
    }

    .rh5v-DefaultPlayer_component{
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      // z-index: -100;
      background-size: cover;
      overflow: hidden;
      // z-index: 99;
      border-radius: 50%;
      height: 310px;
      // width: 315px;
      object-fit: cover;
    }
    video{
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      // z-index: -100;
      background-size: cover;
      overflow: hidden;
      // z-index: 99;
      border-radius: 50%;
      height: 310px;
      // width: 315px;
      object-fit: cover;
    }
    img {
      border-radius: 50%;
      // width: 397px;
      height: 295px;
      width: 295px;
      // height: 100%;
      object-fit: cover;
      object-position: top;
      margin-top: 8px;
    }
  }
  .artist--info--wrapper{
    margin-left:50px;
  }
  .artist-bio {
    margin-top: 30px;
    min-height: 100px;
    p {
      text-align: left;
      font: normal normal bold 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .artist--info {
    // margin-top: 100px;
    // margin-left: 10px;
  }
  .artist--name {
    h1 {
      text-align: left;
      font: normal normal bold 35px/47px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      display: inline-block;
    }
    img {
      display: inline-block;
      margin-left: 20px;
      margin-top: -20px;
    }
  }
  .artist__cats ul {
    padding: 0;
    list-style: none;
    li {
      // width: 102px;
      // padding: 0 10px;
      padding-right: 5px;
      height: 26px;
      // background: #8cb7b8 0% 0% no-repeat padding-box;
      border-radius: 6px;
      text-align: center;
      opacity: 1;
      display: inline-block;
      text-align: left;
      font: normal normal bold 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #acacac;
      opacity: 1;
    }
  }
  h3 {
    text-align: center;
    font: Bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #cb2a6f;
    opacity: 1;
  }
  .downloadable {
    width: 100%;
    margin-top: 50px;
    h5 {
      // width: 164px;
      min-height: 30px;
      text-align: center;
      font: Semibold 16px/16px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      font-size: 16px;
      font-weight: 600px;
    }
    .gradient {
      width: 100%;
      height: 55px;
      background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #13131d;
      border-radius: 4px;
      opacity: 1;
      text-align: center;
      padding-top: 15px;
      text-align: center;
      font: Bold 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      margin-bottom: 15px;
      // display: inline-block;
      cursor: pointer;
    }
  }
  // .advertising {
  //   // width: 217px;
  //   height: 583px;
  //   background-color: #fff;
  //   margin-left: 50px;
  // }
  span {
    // margin-left: 10px;
    font-size: 20px;
    vertical-align: middle;
  }
  .Overlay {
    background-color: transparent;
  }
}
.modali {
  // text-align: center;
  padding: 10px 50px;
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .modali-title {
    font: Bold 22px/59px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    margin-left: 20px;
    max-width: 100px;
  }
  .forma {
    .row {
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 0;
    }
    input[type="checkbox"] {
      margin-left: 15px;
    }
    .fullwidth {
      color: white;
      width: 100%;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid #fff;
      outline: none;
    }
    label {
      font: Bold 16px/28px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .checkbox {
      margin-left: 10px !important;
    }
    select {
      margin: 15px 0;
      color: white;
      width: 100%;
      border: none;
      padding: 10px 0;
      background: none;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      font-weight: bold;
    }
    .submitform {
      width: 100%;
      border: none;
      background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #13131d;
      border-radius: 4px;
      color: white;
      padding: 7px;
    }
  }
  .order--main--info {
    text-align: center;
    margin-bottom: 50px;
  }
  .paragraph {
    font: normal normal bold 19px/28px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .labels {
    text-align: right;
    label {
      padding-left: 10px;
    }
    .con1 {
      display: inline-block;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 15px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .con1 input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: lightgrey;
      border-radius: 10%;
    }

    /* When the radio button is checked, add a blue background */
    .con1 input:checked .checkmark {
      background-color: #17cbf2;
    }
  }
}

.kekbox {
  margin-left: 10px;
}

.ReactModal__Content {
  position: absolute;
  inset: 50% 40px 40px 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(47, 53, 90);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 675px;
  min-height: 580px;
  border-radius: 50px;
}

.check-boxat {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 18px;
    height: 18px;
    background: transparent linear-gradient(268deg, #3f5cc8 0%, #e12160 100%) 0%
      0% no-repeat padding-box;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.videos--container {
  margin-top: 100px;

  video {
    height: 475px;
    width: 100%;
    // width: 350px !important;
  }
}

.playpause {
  background-image: url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .profile--wrapper{
    .artist--info--wrapper{
      margin-left:0;
      text-align:center;
    }
    .artist-bio {
      p{
        text-align:center;
      }
    }
    .downloadable{
      margin:0;
    }
    .mobile--responsetime{
      p {
        margin-top: 15px;
        text-align: center;
        font: normal normal bold 16px/0px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
      span {
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.7;
        vertical-align: text-bottom;
      }
    }
  }
  .modali{
    padding:0;
    .modali-title {
      font: Bold 22px/59px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      margin-left: 20px;
      max-width: 100%;
      display:block;
      width:100%;
    }
  }
  .profile--wrapper {
    .profile--image--text {
      display: none;
    }

    .artist--info {
      padding: 30px 0;
      // padding-left: 50px;
      // text-align: center;
      .artist--name {
        h1 {
          text-align: left;
          font: normal normal bold 21px/47px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
      // .artist__cats {
      //   text-align: center;
      // }
    }
  }

  .ReactModal__Content {
    position: absolute;
    inset: 50% 40px 40px 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(47, 53, 90);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 99% !important;
    min-height: 580px;
    border-radius: 0 !important;
  }
  .controlat{
    display:none;
  }
}

.error--msg {
  background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #13131d;
  text-align: center;
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-top: 30px;
  padding: 10px 0;
  display: block !important;
}

.video {
  height: 650px;
}

.video--container:hover {
  .controlat {
    display: block;
  }
}

.controlat {
  left: 50%;
  top: 50%;
  position: absolute;
  cursor: pointer;
  display: none;
  img {
    width: 40px;
    object-fit: contain;
    // height: 60px;
    // object-fit: cover;
  }
}
.scale--container{
  position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #1d223e;
    z-index: 999999;
}