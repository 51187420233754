.mobile--show {
  position: absolute;
  background: #13172a;
  min-height: 110px;
  padding: 15px 45px;
  z-index: 999999;
 
  top: -1;
  right: 0;
  top: 127px;
  width: 100%;
  transition: all 0.5s ease;
  .mobile--buttons {
    margin-top: 30px;
  }
  i {
    display: none;
  }
  .profile__nav__links {
    text-align: left;
  }
  button {
    text-align: left;
  }
  .name {
    justify-content: left;
    display: block;
    border-image-source: linear-gradient(90deg, #414090 0%, #ae105e 100%);
    border-image-slice: 3;
    border-bottom: 3px solid;
    border-top: 3px solid;
    border-left: 3px solid;
    border-right: 3px solid;
    padding: 10px 5px;
    margin: 30px 0;
    span {
      display: inline-block;
      text-align: center;
    }
    // img {
    //   width: 150px;
    //   height: 150px;
    //   margin: 0 auto;
    // }
  }
  .profile__nav__links {
  }
  &:hover {
    .under__profile__nav {
      display: none;
    }
  }

  .mobile-dropdown-nav {
    display: none;
  }
}

.menu---container{
  display:none;
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {

  .menu---container{
    display:block;
  }
  
  .mobile--show {
    .mobile-dropdown-nav {
      display: block;
    }
  }
}
