.mobilecat--container{
    .cat--box{
        margin:15px;
        position:relative;
        img{
            opacity:0.5;
        }
        h5{
            position: absolute;
            bottom: 10px;
            color: #fff;
            max-width: 100%;
            word-break: break-word;
            padding: 10px;
        }
    }
}