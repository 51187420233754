.settings--wrapper {
  // margin-top: 50px;
  .sidebar {
    width: 349px;
    background-color: #12162d;
    color: #fff;
    // height: 100vh;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -10px;
    img {
      width: 249px;
      height: 249px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
    h6 {
      text-align: center;
      font: normal normal bold 35px/47px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      margin-top: 30px;
      width: 249px;
      // margin:0 auto;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      .ikona {
        float: left;
        width: 13px;
        height: 17px;
        margin-right: 20px;
        margin-top: 5px;
      }
      .next {
        float: right;
        width: 13px;
        height: 17px;
      }
      list-style: none;
      // border-bottom: 1px solid white;
      padding: 10px 0;
      text-align: left;

      a {
        text-align: left;
        font: normal normal bold 18px/24px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.7;
        transition: all 0.2s;
      }
      a:hover {
        opacity: 1;
        font-size: 19px;
      }
    }
  }
  p {
    font: Regular 16px/16px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .settings--content {
    background-color: #1d223e;
    height: 100%;
    padding: 30px 50px;
    .location--name {
      font: Bold 18px/36px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 0.4;
      cursor: pointer;
    }
  }
}


$breakpoint-mobile: 768px;

@media (max-width: $breakpoint-mobile){
 
  .settings--wrapper{
    .sidebar{
      // display:none;
      position:absolute;
      // width:0;
      left:-425px;
      width:100%;
      z-index:9;
      height:100%;
      padding-top:0;
      transition: all .5s ease;
    }
    .settings--content{
      padding:30px 0;
    }
  }
  .edit-profile{
    h1{
      font-size:25px;
    }
    label{
      margin-left:0;
      .bottom{
        background-color: rgba(0, 0, 0, 0.7);
      border-bottom-left-radius: 389px;
      border-bottom-right-radius: 375px;
      border-bottom: 0;
      position: absolute;
      bottom: 170px;
      width: 300px;
      height: 139px;
      bottom: 2p;
      top: 160px;
      padding: 2rem 2rem;
      /* opacity: 0; */
      -moz-transition: opacity 500ms linear, visibility 0s linear 500ms;
      -o-transition: opacity 500ms linear, visibility 0s linear 500ms;
      -webkit-transition: opacity 500ms linear, visibility 0s linear;
      -webkit-transition-delay: 0s, 500ms;
      transition: opacity 500ms linear, visibility 0s linear 500ms;
      }
      img{
        border-radius: 100%;
        display: flex;
        flex: 0 0 150px;
        height: 300px;
        width: 300px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        object-fit: cover;
        object-position: top;
      }
    }
    

  } 
}

$breakpoint-mobile: 768px;

@media (max-width: $breakpoint-mobile){
  h1{
    font-size:25px;
  }
}

.mobile--profile--menu{
  img{
    height: 35px;
    width: 35px;
    margin-top: 15px;
    position: absolute;
    right: 35px;
    z-index: 99;
  }
}