.categories--list {
  margin-top: 20px;
  li {
    padding-right: 15px !important;
    a {
      text-align: center;
      font: Bold 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #f2f2f2;
      opacity: 1;
      text-decoration: none;
      position: relative;
      padding-bottom: 2px;
      background-size: 200% auto;
      background-image: linear-gradient(
        to right,
        #f6d365 0%,
        #fda085 51%,
        #f6d365 100%
      );
    }
    a:hover {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      height: 1px;
      width: 100%;

      background-position: right center;
      font-weight: bold;
    }
    .dropdown--menu {
      // display: none;
      display: block;
      visibility: hidden;
      position: absolute;
      background-color: #13172a;
      font-size: 14px;
      padding: 10px 10px;
      z-index: 9999;
      min-width: 500px;
      width: 100%;
      color: #c6c6c6;
      a {
        color: #c6c6c6;
        text-decoration: none;
      }
    }
  }
  li:hover {
    position: relative;

    ul {
      visibility: visible;
    }
  }
}

.menu--container {
  // margin: 15px 0;
  margin-top: 20px;
}
.nav--icon {
  // flex: 1 1 auto;
  // margin: 10px;
  margin: 0 5px;
  padding: 5px 10px;
  text-align: center;
  // text-transform: uppercase;
  transition: 0.8s;
  // background-size: 200% auto;
  color: #ffffff;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  // box-shadow: 0 0 20px #eee;
  // border-radius: 10px;
  position: relative;
  text-decoration: none;

  font: normal normal bold 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.7;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */
.link--border {
  // display: none;
  transition: width 0.8s;
  margin-bottom: 5px;
  background: transparent linear-gradient(90deg, #414090 0%, #ae105e 100%) 0% 0%
    no-repeat padding-box;
  height: 3px;
  width: 90%;
  position: absolute;
  bottom: -5px;
  left: 10px;
  animation: mymove 3s;
  animation-iteration-count: infinite;
  display: block;

  // padding: 0 5px;
}
// @keyframes mymove {
//   from {
//     left: 0px;
//   }
//   to {
//     left: 10%;
//   }
// }
.nav--icon:hover {
  // background-image: linear-gradient(
  //   to right,
  //   #e12160 0%,
  //   #3f5cc8 51%,
  //   #e12160 100%
  // );
  // background-position: right center; /* change the direction of the change here */
  // color: #fff;

  opacity: 1;
  color: #fff;
  text-decoration: none;
  // font-weight: bold;
  // font-size: 18px;
  transform: scale(0.5);
  .link--border {
    display: block;
    background-position: right center;
    width: 100%;
  }
}

.nav--icon-2 {
  // background: transparent linear-gradient(268deg, #3f5cc8 0%, #e12160 100%) 0%
  //   0% no-repeat padding-box;
}

/* Magic Stuff End -> */
