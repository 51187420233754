body {
  background-color: #1d223e;
}

.advert {
  width: 100%;
  height: auto;
  background-color: white;
}


.profileimage {
  .circle--shape {
    .upload--container {
      display: none;
      position: absolute;
      top: 180px;
      left: 80px;
      span {
        text-align: left;
        font: normal normal bold 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
    .upload--buttoni {
      position: absolute;
      top: 50%;
      left: 25%;
      display: none;
    }
    width: 247px;
    height: 247px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    // border: 1px solid red;
    img {
      display: inline;
      margin: 0 auto;
      margin-left: -50%; //centers the image
      height: 100%;
      width: auto;
      width: 500px;
    }
    p {
      color: white;
      font-size: 25px;
      font-weight: 500;
    }
    &:hover {
      img {
        opacity: 0.6;
      }
      .upload--container {
        display: block;
      }
      .upload--buttoni {
        display: block;
      }
    }
  }

  .profile--name {
    margin-top: 30px;
    font: normal normal bold 33px/45px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
  }
}

.myprofile__form {
  a {
    color: #cb2a6f;
  }
  a:hover {
    // color: #fff;
    -webkit-background-clip: text;
    color: #464646;
    display: inline-block;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      to right,
      #cb2a6f,
      #952d71,
      #602c64,
      #32244b,
      #13172a
    );
  }
  p {
    color: white;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  span {
    text-align: left;
    font: normal normal bold 21px/28px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 10px;
    display: block;
  }

  input {
    width: 100%;
    color: white;
    background: none;
    border: none;
    border-bottom: 2px solid gray;
    text-align: left;
    font: normal normal bold 21px/28px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 30px;
    &:focus {
      outline: none;
    }
  }
  span {
    color: white;
  }
}
.edit {
  height: 23px !important;
  width: 23px !important;
  object-fit: contain;
}
.favorite_celeb_title {
  margin-top: 60px;
  p {
    color: #cb2a6f;
    font: Bold 44px/60px Open Sans;
  }
  .favorite--artist {
    cursor: pointer;
    img {
      width: 260px;
      height: 350px;
      object-fit: cover;
      border-radius: 25px;
    }
    h6 {
      position: absolute;
      bottom: 30px;
      left: 30px;
      visibility: hidden;
      text-align: left;
      font: Bold 22px/30px Open Sans;
      letter-spacing: 0px;
      color: #f2f2f2;
      opacity: 1;
      width: 200px;
    }
    &:hover {
      h6 {
        visibility: visible;
      }
    }
  }
}
.remove {
  display: none;
}


$breakpoint-mobile: 768px;

@media(max-width: $breakpoint-mobile){
  .profile--mobile--container{
    padding: 0 30px !important;
  }
  .profileimage {
  .circle--shape {
    margin-top: 0;
    width: 100%;
    margin: 0 auto;
    position: static;
    overflow: hidden;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    margin-top: 30px;
}
}
  .mobile--profile--intro{
    margin-top:20px;
    // width:
    p{
      color: #cb2a6f;
    font: Bold 20px/60px Open Sans;
    }
  }
  // img{
  //   height: 300px;
  //   width: 300px;
  //   object-fit: cover;
  //   border-radius: 50%;
  
  // }
  .favorite_celeb_title{

    margin-top:30px;
   p{
    font-size:24px;
    margin-top:0;
  }
}
}