.main--wrapper {
  // background: black;
  // margin-top: 222px;
  ul {
    padding: 0;
    list-style: none;

    // li {
    //   display: inline-block;
    //   padding-right: 10px;
    //   text-align: center;
    //   font: Bold 22px/30px Open Sans;
    //   letter-spacing: 0px;
    //   color: #f2f2f2;
    //   opacity: 1;
    // }
  }
}
.advertising {
  // width: 217px;
  // height: 1793px;
  // height: 91%;
  margin-top: 30px;
  // height: 583px;
  // background-color: #fff;
  // margin-left: 50px;
}

.active{
  // background-color:#fff !important;
  position:fixed;
  width:100%;
  z-index:99999;
  transition: height 0.5s ease-in;

  background-color: #13172a;
  box-shadow: 0px 6px 6px #00000029;
  padding-bottom:30px;
  .navbari{
    .main--navi{
      margin-top:0;
    }
  }
}
