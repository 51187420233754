.rightside__container {
  margin-top: 60px;
  padding: 0 70px;
  .welcome__message {
    p {
      font: 32px/68px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    span {
      font: 21px/28px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  .becomevip__form {
    // margin-top: 80px;
    .checkbox {
      padding-left: 2em;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-left: 25px;

      &-inline {
        display: inline-flex;
        margin-right: 20px;
        margin-top: 50px;
        // margin-left: 50px;
      }

      input {
        width: 2em;
        flex-basis: 2em;
        margin-left: -2em;
        position: relative;
        vertical-align: baseline;
      }

      input:not(.reset) {
        position: absolute;
        left: 0;
        margin-left: 0;
        opacity: 0;

        &:focus {
          outline: none;
        }

        &:focus ~ label::before {
          border-color: #0877db;
          box-shadow: 0 0 0 0.1875em rgba(43, 154, 255, 0.2);
        }

        &:active ~ label::before {
          border-color: #0877db;
        }

        &:checked ~ label::before {
          background-position: 0 0;
        }
      }

      input:not(.reset) ~ label::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        vertical-align: top;
        width: 1.5em;
        height: 1.5em;
        border: 0.125em solid #808080;
        background-color: #fff;
        background-size: 100% 100%;
        background-position: 0 1.5em;
        background-repeat: no-repeat;
        box-shadow: 0 0 0 0 rgba(43, 154, 255, 0.2);
        transition: box-shadow 0.15s, background-position 0.15s;
      }

      [type="checkbox"]:not(.reset) ~ label::before {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+Y2hlY2tib3g8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJjaGVja2JveCI+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS05IiBmaWxsPSIjRkZGRkZGIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPjwvcmVjdD4gICAgICAgICAgICA8cGF0aCBkPSJNNCwxMyBMNywxMCBMMTAsMTMgTDE3LDYgTDIwLDkgTDEwLDE5IEw0LDEzIFoiIGlkPSJQYXRoLTMiIGZpbGw9IiNDQjAwMDAiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
      }

      [type="radio"]:not(.reset) ~ label::before {
        border-radius: 100%;
        background: transparent
          linear-gradient(268deg, #3f5cc8 0%, #e12160 100%) 0% 0% no-repeat
          padding-box;
        background-position: 50% 50%;
        background-size: 0px 0px;
        transition: box-shadow 0.15s, background-size 0.15s;
      }
      [type="radio"]:not(.reset):checked ~ label::before {
        background-position: 50% 50%;
        background-size: 100% 100%;
      }

      label {
        display: inline-block;
        vertical-align: baseline;
        user-select: none;
        flex-grow: 1;
        cursor: pointer;
      }

      &-firstline label::first-line {
        font-weight: bolder;
      }

      &:not(.checkbox-firstline) label {
        font-weight: bolder;
      }
    }
    p {
      color: white;
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
    }
    input {
      width: 100%;
      color: white;
      background: none;
      border: none;
      border-bottom: 0.5px solid white;
      &:focus {
        outline: none;
      }
      margin-bottom: 10px;
      //   margin-top: 30px;
      &::placeholder {
        color: #aeaeae;
        text-align: center;
      }
    }
    span {
      font-weight: 500;
      color: white;
    }

    .checkbox {
      input {
        width: 18px;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
    .login__button {
      input {
        border: none;
        padding: 20px;
        border-radius: 4px;
        background: transparent
          linear-gradient(269deg, #3f5cc8 0%, #e12160 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 8px 15px #13131d;
        font-weight: bold;
      }
    }
    .birthday__gender {
      .birthday {
        input {
          &::placeholder {
            color: white;
          }
          padding: 10px !important;
        }
      }
    }
  }
}

.leftside__picture {
  background-image: url("../../images/make_someones_day_2.png");
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: cover;
  height: 900px;
  align-items: flex-end;
  .text__content {
    text-align: center;
    width: 100%;
    padding: 0 40px;
    color: #ffffff;
    position: absolute;
    bottom: 75px;
    p {
      font: Bold 60px/82px Open Sans;
      letter-spacing: 0px;
      font-weight: bold;
    }
    span {
      font: Bold 30px/41px Open Sans;
      letter-spacing: 0px;
      font-weight: 600;
    }
    .personalized {
      padding: 0 74px;
    }
  }
}
.terms--check {
  width: 5% !important;
  margin-right: 10px !important;
  margin-left: 0;
}
