
.vip--container {

  padding: 0;
  a {
    color: #fff;
    text-decoration: none;
  }

  h1 {
    // font: Bold 44px/60px Open Sans;
    // letter-spacing: 0px;
    // color: #cb2a6f;
    // margin-bottom: 30px;

    // new

    font: normal normal bold 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #cb2a6f;
    opacity: 1;
  }
  .pos-relative {
    /* display: table; */
    border: 1px solid #cb2a6f;
    height: 75%;
    margin-left: 20px;
    margin-top: 28px;
    text-align: center;
    width: 77px;
    /* width: 50%; */
    .text-direct {
      // height: 100%;
      transform: matrix(0, -1, 1, 0, 0, 0);
      /* font-size: 36px; */
      /* margin-left: 70px; */
      margin-top: 170px;
      font-size: 25px;
      /* margin: auto 0; */
      width: 100%;
    }
  }
  .custom--col {
    max-width: 89%;
  }
 

  .vip--card {
    transition: all 0.2s ease-in-out;
    user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;

    .inner--vip {
      span{
        width:100%;
      }
      background-color: #000;
      // border-top-left-radius: 8px;
      // border-top-right-radius: 8px;
      border-radius: 8px;
      transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    height: 242px;
    position:relative;
    }
    .card--border {
      background: transparent linear-gradient(90deg, #414090 0%, #ae105e 100%)
        0% 0% no-repeat padding-box;
      height: 6px;
      // border-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      position: relative;
    bottom: -49px;
    }
    .vip--inner {
      background-color: #000;
      // height: 60px;
      padding: 10px 10px;
      text-align: left;
      border-radius: 8px;
      position: absolute;
      width:100%;
      bottom: 0;
      p {
        text-align: left;
        font: Bold 15px/22px Open Sans;
        letter-spacing: 0px;
        color: #f2f2f2;
        opacity: 1;
        margin-bottom: 0;
      }
      .vip--info {
        margin: 5px 0;
        span {
          font: normal normal 300 10px/14px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
    img {
      user-drag: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      height: 187px;
      min-height: 187px;
      width: 100%;
      object-fit: cover;

      border-radius: 8px;
      transition: 0.5s ease;
    }
  }
  .vip--card:hover {
    .inner--vip {
      box-shadow: 0 10px 20px rgba(225, 33, 96, 0.3),
        0 6px 6px rgba(63, 92, 200, 0.3);
    }
    transform: scale(1.2);
    // border: 1px solid #fff;
    img {
      border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

      // border: 5px solid #ae105e;
    }
    .card--border {
      background-position: right center;
    }
  }
  .videos{
    margin-left:20px;
 
  .videoCard{
    background-color:#000;
    // padding:30px 15px;
    border-radius:12px;
    margin-top:30px;
    width:100%;
    min-height:300px;
    .video--container{
      opacity:0.7;
      height:250px;
      // border-radius:12px;

      video{
        width:100%;
        height:250px;
        object-fit:cover;
        // border-bottom-left-radius: 12px;
        // border-bottom-right-radius: 12px;
        border-radius: 12px;
        
        // padding: 45px 15px;
      }
    }
    .video--description{
      margin-top:10px;
      padding:10px 10px;
    }
  }
}
}
.new__comers {
  margin-top: 50px !important;
}
.home--vips {
  .vip--card {
    // padding: 0;
    text-align: left;
  }
}

$breakpoint-desktop: 1455px;
@media(min-width: $breakpoint-desktop){
  //  .carousel-item-width{
  //   width: 171px !important;
  // }
}


$breakpoint-ipad-pro: 1451px;

@media(max-width:$breakpoint-ipad-pro){

  .vip--container {
  .pos-relative {
    /* display: table; */
    border: 1px solid #cb2a6f;
    height: 75%;
    margin-left: 20px;
    margin-top: 35px;
    text-align: center;
    width: 65px;
    /* width: 50%; */
  }
}
}


$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .belowads{
    .col{
      width: 100%;
      flex: 0 0 100%;
       max-width: 100%;
       .single--image{
         margin-bottom:15px;
         img{

           height:100%;
         }
       }
    }
  }
  .videos--container{
    margin-top: 10px !important;
    margin-bottom:100px;
    .item{
      width:80% !important;
      padding-right:10px;
    }
  }
  .home--vips{
    margin:30px 0;
  }
  .vip--container {

    .bottoms-up {
      margin-top:20px;
      cursor:pointer;
      writing-mode: horizontal-tb;
      transform: none;
      font: normal normal bold 24px/33px Open Sans;
        letter-spacing: 0px;
        color: #cb2a6f;
        opacity: 1;
    
        position: static;
        bottom: 43px;
        left: 20px;
        margin-left: 16px;
        label{
          cursor:pointer
        }
    }
    .custom--col {
      // padding: 0 25px;
      width: 100%;
      max-width: 100%;
    }

    .pos-relative {
      /* display: table; */
      border: none;
      height: 100%;
      margin-left: 0;
      margin-top: 0;
      text-align: center;
      width: 100%;
      margin-top: 15px;
      padding: 0 10px;
      /* width: 50%; */
      .text-direct {
        // height: 100%;
        transform: none;
        /* font-size: 36px; */
        /* margin-left: 70px; */
        margin-top: 0;
        font-size: 25px;
        /* margin: auto 0; */
        width: 100%;
      }
    }
  }
}

.react-multiple-carousel__arrow--right {
  height: 100%;
  background: transparent linear-gradient(270deg, #1d223e 0%, #1d223e0f 100%) 0%
    0% no-repeat padding-box !important;
  opacity: 1;
  right: calc(-0.1% + 1px) !important;
  width: 170px;
  border-radius: 0 !important;
}
.react-multiple-carousel__arrow::before {
  text-align: right !important;
}
.react-multiple-carousel__arrow--left {
  display: none !important;
}
button:focus {
    outline: 1px dotted;
    outline: none; 
}

.carousel--container{
  overflow-x: initial;
    -webkit-overflow-scrolling: initial;
    width:auto;
    height: 330px;
    .mobile--carousel{
      overflow: hidden;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      .item{
        display:inline-flex;
        width:150px;
        white-space: pre-wrap;
      }
    }
    .mobile--carousel::-webkit-scrollbar{
      display:none;
    }
    .heading--text{
      font: normal normal bold 24px/33px Open Sans;
      letter-spacing: 0px;
    color: #cb2a6f;
    margin-bottom:20px;
    margin-left:15px;
    }
}