.footer__container {
  background: #13172a;
  .footer--logo--icons {
    position: relative;
    padding:30px 0;
  }
  .store{
    // position:absolute;
    // left:0;
    top: 40px;
  }
  .store img{
    width: 175px;
    margin-right: 30px;
  }
  .footer--image{
    width:310px;
    
    // height:150px;
  }
  .footer--logo {
    width: 269px;
    height: 272px;
  }
  margin-top: 50px;
  h3 {
    font: Bold 34px/46px Open Sans;
    letter-spacing: 0px;
    color: #cb296e;
    opacity: 1;
  }
  ul {
    li {
      display: block;
      text-align: left;
      padding: 10px 0;
      a {
        display: block;
        font: Bold 18px/25px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .become__vip {
    width: 280px;
    height: 70px;
    background: transparent linear-gradient(269deg, #3f5cc8 0%, #e12160 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 8px 15px #13131d;
    border-radius: 4px;
    opacity: 1;
    color: #fff;
    margin: 0;
  }
  .social--media {
    p {
      font: Bold 22px/30px Open Sans;
      letter-spacing: 0px;
      color: #cb2a6f;
      opacity: 1;
      margin-top: 15px;
    }
  }
  .copyright {
    border-top: 2px solid #fff;
    // margin-top: 50px;
    padding: 20px 0;
    a {
      color: #fff;
      margin-right: 50px;
      font: Bold 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .strpokes {
      float: right;
      padding: 0;
      margin-right: 0;
    }
  }
  // .social--links {
  //   li {
  //     display: inline-block;
  //     padding-right: 10px;
  //     a {
  //       img {
  //         height: 40px;
  //         width: 40px;
  //       }
  //     }
  //   }
  // }
  .social--icons {
    text-align: right;
    padding: 0;
    /* vertical-align: bottom; */
    position: absolute;
    right: 0;
    top:50%;
    // bottom: 0;
    a {
      margin-right: 10px;
      vertical-align: bottom;
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.footer-logo {
}
.subscribe--footer {
  border-bottom: 2px solid #fff;
  padding-bottom: 20px;
  // margin: 50px 0;
  padding-right: 0;
  padding-left: 0;
  p {
    text-align: left;
    font: normal normal bold 22px/25px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  input {
    background: #13172a 0% 0% no-repeat padding-box;
    border: 3px solid #e4e4e4;
    border-radius: 7px;
    opacity: 0.64;
    width: 550px;
    height: 60px;
    color: #fff;
    padding-left: 15px;
  }
  input::placeholder {
    color: #fff;
  }
  button {
    // width: 100%;
    // height: 55px;
    background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 8px 15px #13131d;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    // padding-top: 15px;
    text-align: center;
    font: Bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    // margin-bottom: 15px;
    // width: 137px;
    height: 60px;
  }
}
$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .footer__container {
    margin-top: 0;
    padding: 0 30px;
    .subscribe--footer {
      margin: 30px 0;
      padding-bottom: 40px;
      input {
        width: 55%;
        display: inline-block;
        color: #fff;
      }
      input::placeholder {
        color: #fff;
      }
    }
    .footer--logo--icons {
      
      img {
        width: 100%;
        object-fit: contain;
      }
      .social--icons {
        /* text-align: right; */
        /* padding: 0; */
        /* vertical-align: bottom; */
        /* position: absolute; */
        /* right: 0; */
        position: static;
        text-align: left;
        // bottom: -35px;
        margin-top: 30px;
        img {
          height: 30px;
          width: 30px;
        }
      }
    }
    .copyright {
      border-top: 2px solid #fff;
      margin-top: 25px;
      padding: 30px 0;
      a {
        margin-top: 15px;
        display: block;
      }
      .strpokes {
        float: none;
        text-align: right;
        margin-top: 30px;
      }
    }
    .store{
      top:0;
      right:15px;
      img{
        width: 48.5%;
        /* margin-bottom: 15px; */
        margin:15px 0;
        margin-right: 4px;
      }
    }
  }
}
