.rightside__container {
  margin-top: 60px;
  padding: 0 70px;
  .radio__button {
    margin-top: 20px !important;
  }
  .gender--spans {
    span {
      margin-left: 12px;
    }
    .gender {
      margin-left: 25px;
    }
  }
  .welcome__message {
    p {
      font: 50px/68px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    span {
      font: 21px/28px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  // .becomevip__form {
  //   // margin-top: 80px;
  //   p {
  //     color: white;
  //     font-size: 25px;
  //     font-weight: 500;
  //     letter-spacing: 1px;
  //   }
  //   input {
  //     width: 100%;
  //     color: white;
  //     background: none;
  //     border: none;
  //     border-bottom: 0.5px solid white;
  //     &:focus {
  //       outline: none;
  //     }
  //     margin-bottom: 10px;
  //     margin-top: 30px;
  //     &::placeholder {
  //       color: #aeaeae;
  //       text-align: center;
  //     }
  //   }
  //   span {
  //     font-weight: 500;
  //     color: white;
  //   }

  //   .checkbox {
  //     input {
  //       width: 18px;
  //       margin-right: 15px;
  //       margin-bottom: 0;
  //     }
  //   }
  //   .login__button {
  //     input {
  //       border: none;
  //       padding: 20px;
  //       border-radius: 4px;
  //       background: transparent
  //         linear-gradient(269deg, #3f5cc8 0%, #e12160 100%) 0% 0% no-repeat
  //         padding-box;
  //       box-shadow: 0px 8px 15px #13131d;
  //       font-weight: bold;
  //     }
  //   }
  //   .birthday__gender {
  //     .birthday {
  //       input {
  //         &::placeholder {
  //           color: white;
  //         }
  //         padding: 10px !important;
  //       }
  //     }
  //   }
  // }
}

.leftside__picture {
  background-image: url("../../images/girl_img.png");
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: cover;
  height: 1100px;
  align-items: flex-end;
  .text__content {
    text-align: center;
    width: 100%;
    padding: 0 40px;
    color: #ffffff;
    position: absolute;
    bottom: 75px;
    p {
      font: Bold 60px/82px Open Sans;
      letter-spacing: 0px;
      font-weight: bold;
    }
    span {
      font: Bold 30px/41px Open Sans;
      letter-spacing: 0px;
      font-weight: 600;
    }
    .personalized {
      padding: 0 74px;
    }
  }
}
