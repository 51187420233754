.banner {
  // background-image: url("./home banner.jpg");
  // background: rgba(255, 0, 0, 0.25), url("./home banner.jpg");

  background: 
  /* top, transparent red, faked with gradient */ linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    /* bottom, image */ url("./home banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  height: 550px;
  h1 {
    color: #fff;
    // width: 988px;
    margin: 0 auto;
    margin-top: 180px;
    font: Bold 50px/68px Open Sans;
    margin-bottom: 15px;
    text-align: center;
  }
  h4 {
    // width: 988px;
    height: 137px;
    text-align: center;
    font: normal normal normal 50px/68px Open Sans;
    letter-spacing: 0px;
    color: #e6e6e6;
    opacity: 1;
    margin: 0 auto;
  }
  span {
    color: #cb2a6f;
  }
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .banner {
    height: 100%;
    h1 {
      margin-top: 0;
      font-size: 22px;
    }
    h4 {
      font: normal normal normal 22px/30px Open Sans;
    }
  }

  .categories--mobile{
    // border:1px solid red;
    width:35%;
    margin: 0 auto;
    // height:100%;
    // height:150px;
    text-align:center;
    .inner-cat--mob{
      background-color: rgba(0, 0, 0, 0.7);
      border-bottom-left-radius: 314px;
      border-bottom-right-radius: 314px;
      border-bottom: 0;
      height: 70px;
      
      // padding: 4rem 5rem;
      // opacity: 0;
      -moz-transition: opacity 500ms linear, visibility 0s linear 500ms;
      -o-transition: opacity 500ms linear, visibility 0s linear 500ms;
      -webkit-transition: opacity 500ms linear, visibility 0s linear;
      -webkit-transition-delay: 0s, 500ms;
      transition: opacity 500ms linear, visibility 0s linear 500ms;
      background-image: linear-gradient(#1d223e, #1d223e), radial-gradient(circle at bottom left, #3f5cc8, #e12160);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: double 8px transparent;
    border-top:none;
    a{
      max-width:100%;
      font-size:15px;
      color:#fff;
      padding-top:15px;
      text-decoration:none;
      display:block;
      font-weight:500;
    }
    }
  }
}
