.update-password {
  input {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
    outline: none;
    color: #fff;
    height: 30px;
    padding: 10px 0;
    &:focus {
      // outline: none;
    }
  }
  button {
    width: 100%;
    // height: 86px;
    height: 60px;
    background: transparent linear-gradient(269deg, #3f5cc8 0%, #e12160 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 8px 15px #13131d;
    border-radius: 4px;
    opacity: 1;
    margin: 0;
    // float: right;
    color: #fff;
    margin-top: 50px;
  }
  margin-top: 70px;
}
h1 {
  text-align: left;
  font: normal normal bold 44px/60px Open Sans;
  letter-spacing: 0px;
  color: #cb2a6f;
  opacity: 1;
}
