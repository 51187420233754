.navbari {
  // height: 220px;
  // width: 100%;
  .main--navi {
    margin-top: 10px;
    .profilii {
      margin-top: 40px;
    }
  }
  .name {
    span {
      text-align: left;
      font: normal normal 600 20px/36px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
  img {
    width: 266px;
    height: 90px;
    // margin-top: 30px;
  }
  input {
    width: 30px;
    border: none;
    // border-bottom: 1px solid #ca2a6f;

    background-color: transparent;
    color: #fff;
    outline: none;
    background-image: url("../../assets/search.png");
    background-size: 17px 17px;
    font-size: 18px;
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 30px;
  }
  .profile--buttons {
    text-align: center;
    position: relative;
    // top: 90px;
    z-index: 9999;
    margin-right: 12px;
    .login--button {
      // border-radius: 4px;
      text-decoration: none;
      // padding: 10px 20px;;
      cursor: pointer;
      padding: 2 5px;
    }
    a {
      display: block;
      // margin-top: 30px;
      font: Bold 13px/30px Open Sans;
      color: #fff;
      // margin-left: 25px;
      text-decoration: none;
    }
    .sign--up--button {
      // padding: 10px 20px;
      color: #fff;

      cursor: pointer;
      border-radius: 4px;
    }
  }
}

.searchbox {
  z-index: 99999999999999999999;
  background-color: #171717;
  transition: height 0.5s ease;
  position: absolute;
  top: 45px;
  border-radius: 26px;
  width: 335px;
  margin: 0 auto;
  // left: 104px;
  right: 20px;
  // padding-top: 30px;

  a{
    display: block;
    width: 100%;
  }

  :first-child() {
    margin-top: 15px;
  }
  p {
    color: #fff;
  }
  .singleartistsearch {
    img {
      border-radius: 50%;
      width: 80px;
      height: auto;
      transition: 0.3s ease-in-out;
    }
  }
  .search--artistat {
    // margin-top: 13px;
    p {
      // display: inline-block;
      // margin-left: 50px;
      text-align: left;
      // font: Bold 16px/0px Open Sans;
      letter-spacing: 0px;
      // color: #ffffff;
      font-weight: bold;
      font-family: Open Sans;
    }

    h6 {
      font: Bold 12px Open Sans;
      letter-spacing: 0px;
      color: #d1d1d1;
      text-align: left;
    }
  }
  .searchi {
    padding: 10px 30px;
    img {
      object-fit: cover;
      height: 50px;
      width: 50px;
      margin-top: 0;
    }
  }
  .searchi:hover {
    img {
      background-image: transparent
        linear-gradient(270deg, #3f5cc8 0%, #e12160 100%);
      transform: scale(1.5);
      box-shadow: 0px 2px 2px 2px rgba(225, 33, 96, 0.3),
        0 6px 6px rgba(63, 92, 200, 0.3);
    }
    p {
      background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
    }
    h6 {
      // background: transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%);
      background: #3f5cc8;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
    }
  }
}

.search-container {
  overflow: hidden;
  float: right;
  height: 4em;
  width: 4em;
  // border-radius: 2em;
  // box-shadow: 0 0 5px #6a5d4f;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}
.search-container:hover,
.search-container:focus,
.search-container:focus-within {
  width: 25em;
  // border-radius: 5px 2em 2em 5px;
  outline: none;
}
.search-container:hover input,
.search-container:focus input,
.search-container:focus-within input {
  display: inline-block;
  width: 19em;
  // padding: 10px;
  color:#fff;
}

input {
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  border-bottom: 1px solid #ca2a6f;
  :before {
    background-image: url("../../assets/search.png");
    width: 17px;
    height: 17px;
  }
  &::placeholder {
    color: #fff;
    opacity:0.7
  }
}
input::placeholder{
  color:#fff !important;
}
input:focus {
  outline: none;
  box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.25),
    0 1px 5px rgba(0, 0, 0, 0.15);
  // border: 1px solid #ca2a6f;
}
.box {
  width: 50%;
  // height: 50px;
  max-width: 100%;
  background: var(--bg2);
  margin: 0 auto;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.box-1 {
  border: 3px solid;
  border-radius: 6px;
  // border-image-source: url(/url/to/some/fancy/image.jpg);
  // border-image-slice: 60 30;

  border-image-source: linear-gradient((90deg, #414090 0%, #ae105e 100%));
  border-image-slice: 1;
}

.input-box {
  // display: none;
  display: inline-block;
  border-image-source: linear-gradient((90deg, #414090 0%, #ae105e 100%));
  border-image-slice: 1;
  border-bottom: 3px solid;
  background:#13172a;
}
.input-box:hover {
  // display: block;
  border-image-source: linear-gradient((90deg, #414090 0%, #ae105e 100%));
  border-image-slice: 1;
  border-bottom: 3px solid;
  input:focus > .input-box {
    border: 1px solid;
  }
}

.select {
  .ant-select {
    // width: 50% !important;
    // width: 30px;
    background: transparent !important;
    color: #fff;
    .ant-select-selector {
      background: none !important;
      border: none;
      color: #fff;
      background-image: url("../../assets/Globi.png") !important;
      background-size: 18px 18px !important;
      // font-size: 18px;
      background-repeat: no-repeat !important;
      background-position: bottom;
      width: 120px;
      background-position-y: 9px !important;
    }
    img {
      height: 8px !important;
      width: 8px !important;
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    .act-select-single {
      .ant-select-show-arrow {
        .ant-select-selection-item {
          padding-right: 10px;
          padding-top: 3px;
        }
      }
    }
  }
  .ant-select-selection-item {
    padding-right: 10px !important;
    padding-top: 3px !important;
  }
}



$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .menu---container{
    transition:all 0.5s ease-in;
  }
  .searchbox{
    width: 93%;
  }
  .input-box{
    width:100%; 
    border-top:3px solid;
    border-right:3px solid;
    border-left:3px solid;
    padding-left:5px;
  }
  .navbari {
    input{
      width:100%;
    }
    img {
      width: 266px;
      height: 90px;
    }
    .search-container:hover{
      width:20em;
    }
    // .searchbox{
    //   right: 0;
    // }
    .main--navi {
      margin: 0;
      .profilii {
        margin-top: 0;
      }
    }

    .profile--buttons {
      // display: none;
    }
    .mobile--nav {
      padding-top: 30px;
    }
    .search-container:hover{
      // float:right;
      width:100%;
      float:none;
     
    }
    .search-container{
      // float:right;
      width:100%;
      float:none;
      width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height:3em;
    padding:0;
    margin-top:5px;
    margin-bottom:5px;
    }

  }
}


$breakpoint-mobile : 600px;

@media(max-width: $breakpoint-mobile){
  .navbari{
    img {
      width: 175px;
      height: 75px;
      object-fit: contain;
    }
  }
}