.orders__wrapper {
  background-color: #1d223e;
  padding-bottom: 50px;
  margin-top: 50px;
  // height: 800px;
  .columna {
    // width: 100%;
    background-color: #13172a;
    margin-top: 2px;
    height: 50px;
    border-radius: 8px;
    font: normal normal normal 14px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    .coli {
      padding-top: 12.5px;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .intro--orders {
    // margin: 50px 0;
    margin-top: 50px;
    margin-bottom: 50px;

    h1 {
      // text-align: center;
      font: Bold 44px/60px Open Sans;
      letter-spacing: 0px;
      color: #cb2a6f;
      opacity: 1;
      // padding: 50px 0;
      margin: 0;
    }
    .selectBoi > div > span {
      color: rgb(104, 0, 179);
      // font-size: 19px !important;
      // font-weight: bolder;
      right: 6px;
      // margin-top: -8px;
    }
    .ant-select {
      width: 100% !important;
      .ant-select-arrow {
        color: #fff;
      }
      .ant-select-dropdown {
        background-color: transparent !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        /* background-color: #fff; */
        border: none;
        border-radius: 2px;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border: none;
        color: #fff;
        font-weight: bold;
        background: transparent;
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: #fff !important;
        font-weight: 600;
        background-color: transparent !important;
      }
      .ant-select-selector {
        background: none !important;
        border: none !important;
        color: #fff;
        font-size: 18px;
        text-align: left;
      }
      .ant-select-selection-item {
        color: #fff;
      }
    }
  }
  table {
    // background-color: red;
    width: 100%;
    tr:nth-child(even) {
      background: transparent;
      height: 56px;
      padding: 0 5px;
    }
    tr:nth-child(odd) {
      background: #13172a;
      height: 56px;
    }
    td {
      padding: 0 20px;
    }
  }
  // .overlay {
  //   background: rgba(0, 0, 0, 0.8);
  // }

  .border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    // text-align: center;
    justify-content: center;
    height: 55px;
    display: flex;
    padding-top: 12.5px;
    font: normal normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    // width: 150px;
    margin-bottom: 5px;
    select {
      border: none;
      background: transparent;
      width: 100%;
      color: #fff;
      option {
        background: #1d223e;
        color: #fff;
        // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  .card--gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
    // text-align: center;
    justify-content: center;
    // height: 55px;
    display: flex;
    // padding-top: 12.5px;
    padding: 10px 10px;
    font: normal normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    // width: 150px;
    margin-bottom: 5px;
  }

  .carda {
    background:#252D57;
    img {
      height: 75px;
      width: 100%;
      background: transparent;
      border-radius: 50%;
      object-fit: cover;

    }
    p {
      margin: 0;
    }
    .play {
      margin-top: 15px;
    }
  }
  .border-gradient-purple {
    // border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    border-image-source: linear-gradient( 
      90deg
       ,#414090,#ae105e);
     border-image-slice: 1;
    //  border-bottom: 3px solid;
    //  border-top: 3px solid;
    //  border-right: 3px solid;
    //  border-left: 3px solid;
    border-width: 3px;
    padding:15px;
  } 
}

.video {
  height: 650px;
}

.video--container:hover {
  .controlat {
    display: block;
  }
}

.controlat {
  // left: 320px;
  // top: 320px;
  position: absolute;
  cursor: pointer;
  display: none;
  img {
    width: 40px;
    object-fit: contain;
    // height: 60px;
    // object-fit: cover;
  }
}

table { 
  width: 100%; 
  border-collapse: collapse; 
}
/* Zebra striping */
tr:nth-of-type(odd) { 
  background: #414090; 
}
th { 
  background: #333; 
  color: white; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #414090; 
  text-align: left; 
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #414090; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #414090; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Artist name"; }
	td:nth-of-type(2):before { content: "Shout type"; }
	td:nth-of-type(3):before { content: "Country"; }
	td:nth-of-type(4):before { content: "Currency"; }
	td:nth-of-type(5):before { content: "Date"; }
	td:nth-of-type(6):before { content: "Order text"; }
	td:nth-of-type(7):before { content: "Payment ID"; }
	td:nth-of-type(8):before { content: "Platform"; }
	td:nth-of-type(9):before { content: "Video is for"; }
	// td:nth-of-type(10):before { content: "Arbitrary Data"; }
}