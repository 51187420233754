.wrapper__profile__nav {
  // border-radius: 30px 30px 30px 30px;
  // border-radius: 6px;
  // padding: 8px 15px;
  // background-color: #06070f;
  // border: 1px solid #ca2a6f;
  // width: 200px;
  // float: right;
  width: 100%;
  position: relative;
  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  i {
    margin-right: 3px;
    color: ghostwhite;
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .under__profile__nav {
    // position: absolute;
    background-color: #06070f;
    // width: 100px;
    // left: 15px;
    right: 45px;
    display: block;
    z-index: 999;
    border-radius: 0 0 10px 10px;
    padding-bottom: 10px;
    position: absolute;
    /* top: 0; */
    transition: all 0.7s ease-in;
    border-top: none;
    margin-top: 35px;
    padding: 0 20px;
    z-index:999;
  }
  // .under__profile__nav:hover{

  // }
  .profile__nav__links {
    padding: 10px 0;
    text-align: center;
    .settings {
      color: white;
      text-decoration: none;
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .logout {
      color: #ff1414;
      text-decoration: none;
      background: none;
      border: none;
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
    }
    a {
      text-align: center;
      font: normal normal 300 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  // &:hover {
  //   .under__profile__nav {
  //     display: block;
  //   }
  // }
}

.mobile-dropdown-nav {
  display: none;
}
