.contact{
    margin-top:60px;
    h1{
        text-align:center;
        font-size:41px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom:60px;
    }
    h6{
      text-align:center;
      font-size:22px;
      letter-spacing: 0px;
      color: #e12160;
      opacity: 1;
      margin-bottom:60px;
    }

    .contact--container{
        width:800px;
        margin:0 auto;

        
        
        input{
            height: 71px;
            background: #111528 0% 0% no-repeat padding-box;
            opacity: 0.8;
            width: 100%;
            margin-bottom:30px;
            padding-left:10px;
            border:none;
        }
        textarea{
            height: 348px;
            background: #111528 0% 0% no-repeat padding-box;
            opacity: 0.8;
            width:100%;
            margin-bottom:30px;
            padding-left:15px;
            // color:#FFFFFF;
            padding:10px;
            border:none;
            &::placeholder{
              color:#fff
            }
        }
        textarea:-webkit-input-placeholder {
            color: #fff  !important;
            opacity:.8;
          }
          
          textarea:-moz-placeholder { /* Firefox 18- */
            color: #fff !important;  
            opacity:.8;
          }
          
          textarea:-moz-placeholder {  /* Firefox 19+ */
            color: #fff !important;  
            opacity:.8;
          }
          
          textarea:-ms-input-placeholder {
            color: #fff !important;  
            opacity:.8;
          }
          
          textarea::placeholder {
            color: #fff !important;
            opacity:.8;  
          }

        button{
            border: none;
        padding: 5px 10px;
        background: transparent linear-gradient(
    269deg
    , #3f5cc8 0%, #e12160 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 15px #13131d;
        font-weight: bold;
        border-radius: 12px;
        width: 194px;
        height:55px;
        margin-left: 0;
        font-size:27px;
        }
    }
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .contact{    
    .contact--container{
      width:100%;
    }
  }
}