.leftside__container {
  margin-top: 60px;
  padding: 0 70px;
  .welcome__message {
    p {
      font: 50px/68px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    span {
      font: 21px/28px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  .login__form {
    margin-top: 80px;
    label {
      font-weight: bold;
    }
    p {
      color: white;
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 1px;
    }
    input {
      //   padding: 0 60px;
      // padding-left: 10px;
      width: 100%;
      color: white;
      background: none;
      border: none;
      border-bottom: 0.5px solid white;
      &:focus {
        outline: none;
        background-color: transparent;
      }
      margin-bottom: 10px;
      margin-top: 30px;
      &::placeholder {
        color: #fff;
        font-weight: bold;
      }
    }
    span {
      font-weight: 500;
      color: white;
    }
    .checkbox {
      input {
        width: 18px;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
    .login__button {
      input {
        border: none;
        padding: 20px;
        border-radius: 4px;
        background: transparent
          linear-gradient(269deg, #3f5cc8 0%, #e12160 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 8px 15px #13131d;
        font-weight: bold;
      }
    }
  }
  .social__login {
    margin-top: 15px;
    padding: 0 50px;
    .social__button {
      width: 100%;
      padding: 15px;
      background: #8cb7b8 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      color: #ffffff;
      i {
        font-size: 20px;
      }
    }
  }
}

.rightside__picture {
  background-image: url("../../images/make_someones_day_2.png");
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: cover;
  //   height: 910px;
  align-items: flex-end;
  .text__content {
    text-align: center;
    width: 100%;
    padding: 0 40px;
    color: #ffffff;
    position: absolute;
    bottom: 25px;
    p {
      font: Bold 60px/82px Open Sans;
      letter-spacing: 0px;
      font-weight: bold;
    }
    span {
      font: Bold 30px/41px Open Sans;
      letter-spacing: 0px;
      font-weight: 600;
    }
    .personalized {
      padding: 0 74px;
    }
  }
}
