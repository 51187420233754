.vip--card {
  margin-bottom: 30px;
}
.bottoms-up {
  cursor:pointer;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  font: normal normal bold 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #cb2a6f;
    opacity: 1;

    position: absolute;
    bottom: 43px;
    left: 20px;
    label{
      cursor:pointer
    }
}
.😳 {
  background-color: red;
}
.♥ {
  color: blue !important;
}
.pos-relative {

  /* width: 50%; */
  .text-direct {
    color: #cb2a6f;
  
  }
}
.singleCats{
  .pos-relative{
    cursor:pointer;
  }
}
.custom--col {
  max-width: 89%;
}

.cats--container {
  h1 {
    margin: 0;
    margin-top: 1.5rem;
  }
  p {
    text-align: left;
    font: normal normal bold 15px/20px Open Sans;
    letter-spacing: 0px;
    color: #737995;
    opacity: 1;
  }


  .filters {
    display:none;
    // min-height:150px;
    transition: height .3s ease-in;
    margin-bottom: 20px;
    margin-top: 10px;
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      background: transparent;
      border-radius: 4px;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      // content: "";
      // width: 8px;
      // height: 8px;
      // background: linear-gradient(90deg, #414090 0%, #ae105e 50%);
      // position: absolute;
      // top: 6px;
      // left: 6px;

      // transition: all 0.2s ease;

      content: "";
      width: 16px;
      height: 14px;
      background: linear-gradient(90deg, #414090 0%, #ae105e 50%);
      position: absolute;
      top: 2px;
      left: 1px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .open--filters{
    display:block;
  }
 
}
